import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, keywords, title, ldJsonData, children }) {
    return (
        <StaticQuery
            query={detailsQuery}
            render={data => {
                const metaDescription =
          description || data.site.siteMetadata.description
                return (
                    <Helmet
                        htmlAttributes={{
                            lang,
                        }}
                        title={title}
                        titleTemplate={`%s | ${data.site.siteMetadata.title}`}
                        meta={[
                            {
                                name: 'description',
                                content: metaDescription,
                            },
                            {
                                property: 'og:title',
                                content: title,
                            },
                            {
                                property: 'og:description',
                                content: metaDescription,
                            },
                            {
                                property: 'og:type',
                                content: 'website',
                            },
                            {
                                name: 'twitter:card',
                                content: 'summary',
                            },
                            {
                                name: 'twitter:creator',
                                content: data.site.siteMetadata.author,
                            },
                            {
                                name: 'twitter:title',
                                content: title,
                            },
                            {
                                name: 'twitter:description',
                                content: metaDescription,
                            },
                        ]
                            .concat(
                                keywords.length > 0
                                    ? {
                                        name: 'keywords',
                                        content: keywords.join(', '),
                                    }
                                    : []
                            )
                            .concat(meta)}
                    >
                        { ldJsonData && <script type='application/ld+json' dangerouslySetInnerHTML={{__html: JSON.stringify(ldJsonData)}}></script> }
                        { children }
                    </Helmet>
                )
            }}
        />
    )
}

SEO.defaultProps = {
    lang: 'en',
    meta: [],
    keywords: [],
    description: 'Everything you need to get an A+ in IGCSE, GCSE and O Level Maths. Questions, Explanations, 24/7 Tutoring, Notes & Analytics included. Shown to increase student test scores by over 20%',
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired,
    ldJsonData: PropTypes.string,
    children: PropTypes.node
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author,
        siteUrl
      }
    }
  }
`
