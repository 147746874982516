export const QS_GUARANTEE_DATA = [
    {
        background:'bg-light-yellow',
        spacing: 'pt-3 pb-3',
        title: 'The QuickSense program',
        content: [
            'To qualify for the A*- A guarantee you don\'t need to study 10 hours every day like other companies.',
            'Simply get and maintain \'3 stars\' across all the lessons in the course before the date you are scheduled to sit your exam and you will automatically qualify for the Guarantee. ',
            'This works to out be an average of 8/10 questions correct per lesson and you can practice the questions as many times as you need! ',
            'It\'s that simple. '

        ]
    },
]

export const QS_GUARANTEE_FAQ_DATA = [
    [
        'Does this mean I don\'t have to do the work? ',
        'No, you\'ll still need to put in the work and learn the content — there\'s no getting around that. But by using QuickSense will make you learn much more efficiently, waste less time and reduce your stress levels — working smarter, not harder. '
    ],
    [
        'How are \'stars\' calculated?',
        'Stars are calculated based on the number of questions you get correct. You can get 3 stars if you get an average of 8/10 questions correct in each lesson on that topic. You don\'t have to get it all right in the first go (in fact you probably won\'t), just keep practising until you reach 3 stars. '
    ],
    [
        'How long will it take to finish the course?',
        'Everybody learns at a different pace, but on average it takes 4 - 8 weeks to complete the course — approximately 90 hours for the average student. The earlier you start, the less you will have to do per day. If you start at the beginning of the academic year you will only need to do 15 minutes per day, but if you only start 4 weeks before the exam you will need to put in about 3 hours per day. '
    ],
    [
        'Is QuickSense the only study I need to do? ',
        'We have designed the course to be completely standalone and the only resource you need - you don\'t need to show us any other work to be eligible for the guarantee. ',
        'However, we have found that our best-performing students also do a series of past papers as practice before the exam to hone exam technique, timing and reinforce content — we strongly recommend doing at least 4 past papers under exam conditions (pen and paper, timed, in a quiet room) before your exam.'
    ],
    [
        'What if I miss some topics?',
        'If you miss some topics (i.e. you don\'t get 3 stars in every topic) you are not eligible for the guarantee. If you have some legitimate reason for not completing all the topics, please email us and we will decide on a case by case basis and at our full discretion. '
    ],
    [
        'Is A*- A the same as grade 9 - 7 for my exam board?',
        'For the Edexcel exam board (and some other UK based boards) a grade of 9 - 7 is equivalent to A*- A and we will treat it as such. '
    ],
    [
        'What if exams are cancelled?',
        'If the  IGCSE, GCSE or O-level exams are cancelled (e.g. due to COVID) then you are still eligible for the guarantee based on the final grade you achieve (either teacher assessed, internal exams or another method). Normally we would look at your completion on the date of your exam, but under these circumstances, we will still need some proof (email, a note from your teacher, etc) of the date your grade was assessed.'
    ],
    [
        'Is this too good to be true? ',
        'No this is 100% legitimate! We created QuickSense as the most efficient way to learn and we are 100% sure that it works — if you follow the program. That\'s why we can, and want to offer this type of guarantee - it\'s our commitment to you.'
    ]
    
]