import React from 'react'
import PropTypes from 'prop-types'
//Components
import Col from 'react-bootstrap/Col'
import Button from '../Button/button'
import Section from '../Section/section'

class GetStarted extends React.Component {

    render(){
        return <Section background={this.props.background} center={true}>
            <Col className='d-flex flex-column justify-content-center align-items-center text-center' lg={8}>
                {
                    !!this.props.superImage && 
                    <figure>
                        <picture>
                            <source data-srcset={this.props.superImageWebp} type='image/webp'/>
                            <img 
                                width={this.props.superImageWidth} 
                                height={this.props.superImageHeight} 
                                data-src={this.props.superImage}
                                loading={'lazy'}
                                className={`rounded lazyload ${this.props.superImageClass}`}
                                alt={this.props.superImageAlt} />
                        </picture>
                    </figure>
                }
                <h2 className='h1 mb-3'>{this.props.title}</h2>
                <p dangerouslySetInnerHTML={{__html: this.props.description}}/>
                <div className='d-flex flex-column flex-sm-row justify-content-around align-items-center'>
                    <Button classes='mt-3 mb-3 px-5 btn btn-primary' href='https://app.quicksense.org/signup' event={this.props.event} eventValues={this.props.eventValues}>{this.props.buttonText}</Button>
                    {!!this.props.bookButton && <Button secondary to={'/contact?book'} classes={'mt-3 mb-3 px-5 ml-3'}>Book a call</Button>}
                </div>
                {!!this.props.secondaryButton 
                  && <Button link
                      isInternal={this.props.secondaryButtonType == 'internal'}
                      to={this.props.secondaryButtonDestination}
                      href={this.props.secondaryButtonDestination}
                      event={this.props.secondaryButtonEvent} 
                      eventValues={this.props.eventValues} 
                      classes={`mt-0 ${this.props.secondaryButtonType =='internal' ? '--underline': ''}`}>
                      {this.props.secondaryButtonText}
                  </Button>
                }
            </Col>
        </Section>
    }
}

GetStarted.propTypes = {
    background: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    buttonText: PropTypes.string,
    buttonVariant: PropTypes.string,
    bookButton: PropTypes.bool,
    secondaryButton: PropTypes.bool,
    secondaryButtonText: PropTypes.string,
    secondaryButtonType: PropTypes.string,
    secondaryButtonDestination: PropTypes.string,
    superImage: PropTypes.string,
    superImageWebp: PropTypes.string,
    superImageWidth: PropTypes.string,
    superImageHeight: PropTypes.string,
    superImageClass: PropTypes.string,
    superImageAlt: PropTypes.string,
    event: PropTypes.string,
    eventValues: PropTypes.object,
    secondaryButtonEvent: PropTypes.string,
    secondaryButtonEventValues: PropTypes.object
}

GetStarted.defaultProps = {
    background: 'bg-light-pink',
    title: 'Try QuickSense for free!',
    description: 'Everything you need to get an A / 7+ in IGCSE, GCSE and O-Level Maths.',
    buttonText: 'Try for free',
    buttonVariant: 'outline-primary',
    event: 'website_sign_up_button_clicked',
    secondaryButton: true,
    secondaryButtonText: 'Unsure? Checkout our A/7+ Guarantee.',
    secondaryButtonType: 'internal',
    secondaryButtonDestination: '/guarantee',
    secondaryButtonEvent: 'website_guarantee_button_clicked',
    eventValues: {
        location: 'Footer'
    }

}

export default GetStarted