import React from 'react'
import { Link } from 'gatsby'
import { PropTypes } from 'prop-types'

class Breadcrumb extends React.Component {
    render(){
        return <nav aria-label='breadcrumb'>
            <ol className='breadcrumb mb-0'>
                {
                    !this.props.hideHomeLink && <li className={`breadcrumb-item ${this.props.listItemClasses}`}>
                        <Link to={'/'} className={`${this.props.linkClasses}`}>
                    Home
                        </Link>
                    </li>
                }

                {
                    this.props.links.map((link, index) => (
                        (index.length == index) || link.last ? 
                            <li aria-current={'page'} className={`active breadcrumb-item ${this.props.listItemClasses && this.props.listItemClasses}`} key={index} style={{textTransform: 'capitalize'}}>
                                {link.name}
                            </li>:
                            <li className={`breadcrumb-item ${this.props.listItemClasses && this.props.listItemClasses}`} key={index} style={{textTransform: 'capitalize'}}>
                                <Link to={link.destination} className={`${this.props.linkClasses}`}>{link.name}</Link>
                            </li>
                    ))
                }
            </ol>
        </nav>
    }
}

Breadcrumb.propTypes = {
    links: PropTypes.array,
    linkClasses: PropTypes.string,
    listClasses: PropTypes.string,
    listItemClasses: PropTypes.string,
    hideHomeLink: PropTypes.bool,
}

export default Breadcrumb
