import React from 'react'

// components
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import SEO from '../components/SEO/seo'
import Layout from '../components/Layout/layout'
import Section from '../components/Section/section'
import GetStarted from  '../components/GetStarted/getStarted'
import Container from 'react-bootstrap/Container'
import Button from '../components/Button/button'

//utils
import { QS_GUARANTEE_DATA, QS_GUARANTEE_FAQ_DATA } from '../utils/pages/qsGuaranteeData'

class CasesPage extends React.Component {

    __renderHeroSection() { 
        return <div>
            <div className={'d-flex align-items-center process-hero mt-5 py-5'} data-sal='fade' data-sal-duration='800' data-sal-delay='1200' data-sal-easing='ease-out-quad'>                
                <Container className='pt-5'>
                    <Row>
                        <Col as='section' lg={6}>
                            <span className='text-secondary'>OUR GUARANTEE</span>
                            <h1>A*- A results – or your money back</h1>
                            <p>If you complete the QuickSense program and do not get an A*- A (9-7 in some exam boards), show us your official exam results and we will refund you in full within 48 hours.</p>
                            <p>{`That's how we're sure QuickSense works`}</p>
                            <Button classes={'mb-4'} primary>Try for free</Button>
                        </Col>
                        <Col as='figure' lg={6}>
                            <img width={'540'} height={'540'} className='w-100' alt='QuickSense A Grade Guarantee displayed on the wall' src='https://res.cloudinary.com/dtkzddu1x/image/upload/v1628552241/QuickSense/Guarantee.svg' />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    }

    render(){
        return <>
            <SEO title='A* Guarantee'/>
            <Layout classes='fw-landing-page'>
                {this.__renderHeroSection()}
                {
                    QS_GUARANTEE_DATA.map((section, index) => (
                        <Section
                            key={index} 
                            animation={section.animation}
                            spacing={section.spacing}
                            background={section.background}
                            title={section.title}
                            titleClasses={section.titleClasses}
                            superTitle={section.super}
                            content={section.content}
                            listContent={section.listContent}
                            contentImage={section.contentImage}
                            contentImageWidth={section.contentImageWidth}
                            contentImageHeight={section.contentImageHeight}
                            contentImageClass={section.contentImageClass}
                            contentImageWebp={section.contentImageWebp}
                            contentImageAlt={section.contentImageAlt}
                        />
                    ))
                }
                {
                    QS_GUARANTEE_FAQ_DATA.map((section, index) => (
                        <Section
                            superTitle={index==0 ? 'FAQ' : ''}
                            spacing= {index==0 ? 'pt-2 mt-2' : 'pt-1 pb-2'}
                            animation={false}
                            key={index} 
                            center={true}
                            title={`${index+1}. ${section[0]}`}
                            titleClasses={'h2'}
                        >
                            <Col lg={8}>
                                {
                                    section.map((content, index) =>(
                                        index>0 &&
                                            <p className='text-primary-high' key={index} dangerouslySetInnerHTML={{__html: content}}/>
                                    ))
                                }
                                { 
                                    index<7 && 
                                        <hr className='w-100 pb-3' />
                                }
                            </Col>
                        </Section>
                    ))
                }
                <GetStarted
                    background='bg-light-yellow'
                    buttonVariant='primary'
                    secondaryButton={true}
                    title={`All you need to do is answer questions `}
                    description={`This might be a lot to take in, but if you use QuickSense you don't need to worry about it. Our algorithm has this built-in and does it all for you. <br/><br/> All you need to do is follow our program and answer the questions.`}
                    secondaryButtonText={`Just answer questions and you will get an A - A* - guaranteed`}
                />
            </Layout>
        </>
    }
}

export default CasesPage