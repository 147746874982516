import React from 'react'
import PropTypes from 'prop-types'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Breadcrumb from '../../components/Breadcrumb/breadcrumb'

class Section extends React.Component {
    render() {
        return <div ref={this.props.refProp} className={`${this.props.spacing} ${this.props.background} ${this.props.containerClasses}`} data-sal={this.props.animation && this.props.animationType} data-sal-duration={this.props.animation && this.props.animationDuration} data-sal-delay={this.props.animation && this.props.animationDelay} data-sal-easing={this.props.animation && this.props.animationEasing}>
            <Container className={` ${this.props.spacing}`} fluid={this.props.fluid}>
                <Row className={`${this.props.spacing} ${this.props.rowClasses} ${(this.props.center || this.props.title) && 'd-flex justify-content-center align-items-center'}`}>
                    {
                        this.props.title ?
                            <Col className='col-lg-8' as='section' lg={8}>
                                {
                                    !!this.props.breadcrumbs &&
                                        <Breadcrumb links={this.props.breadcrumbs}/>
                                }
                                {
                                    !!this.props.superImage && 
                                    <figure>
                                        <picture>
                                            <source data-srcset={this.props.superImageWebp} type='image/webp'/>
                                            <img 
                                                width={this.props.superImageWidth} 
                                                height={this.props.superImageHeight} 
                                                data-src={this.props.superImage}
                                                loading={'lazy'}
                                                className={` lazyload ${this.props.superImageClass}`}
                                                alt={this.props.superImageAlt} />
                                        </picture>
                                        <noscript>
                                            <img 
                                                width={this.props.superImageWidth} 
                                                height={this.props.superImageHeight} 
                                                src={this.props.superImage}
                                                loading={'lazy'}
                                                className={` ${this.props.superImageClass}`}
                                                alt={this.props.superImageAlt} />
                                        </noscript>
                                    </figure>
                                }
                                {!!this.props.superTitle && <strong className='text-secondary text-uppercase'>{this.props.superTitle}</strong>}
                                <h2 className={`h1 mb-4 ${this.props.titleClasses} `}>{this.props.title}</h2>
                                {
                                    !!this.props.content &&
                                        this.props.content.map((data, index) => (
                                            <p key={index} dangerouslySetInnerHTML={{__html: data}}/>
                                        ))
                                }
                                {
                                    !!this.props.listContent &&
                                    <ol>
                                        {
                                            this.props.listContent.map((data,index) => (
                                                <li key={index} className={`${this.props.listItemClasses}`} dangerouslySetInnerHTML={{__html: data}}/>
                                            )) 
                                        }
                                    </ol>
                                }
                                {
                                    this.props.contentImage && <figure className='d-flex align-items-center justify-content-center'>
                                        <picture>
                                            <source data-srcset={this.props.contentImageWebp} type='image/webp'/>
                                            <img 
                                                width={this.props.contentImageWidth} 
                                                height={this.props.contentImageHeight} 
                                                data-src={this.props.contentImage}
                                                loading={'lazy'}
                                                className={`rounded shadow-1 lazyload ${this.props.contentImageClass}`}
                                                alt={this.props.contentImageAlt} />
                                        </picture>
                                        <noscript>
                                            <img 
                                                width={this.props.contentImageWidth} 
                                                height={this.props.contentImageHeight} 
                                                src={this.props.contentImage}
                                                className={`rounded shadow-1 ${this.props.contentImageClass}`}
                                                loading={'lazy'}
                                                alt={this.props.contentImageAlt} />
                                        </noscript>
                                    </figure>
                                }
                            </Col>: null
                    }
                    {this.props.children}
                </Row>
            </Container>
        </div>
    }

}

Section.propTypes = {
    children: PropTypes.node,
    fluid: PropTypes.bool,
    background: PropTypes.string,
    breadcrumbs: PropTypes.array,
    title: PropTypes.string,
    titleClasses: PropTypes.string,
    superTitle: PropTypes.string,
    superImageWebp: PropTypes.string,
    superImageWidth: PropTypes.string,
    superImageHeight: PropTypes.string,
    superImage: PropTypes.string,
    superImageClass: PropTypes.string,
    superImageAlt: PropTypes.string,
    listContent: PropTypes.array,
    listItemClasses: PropTypes.string,
    content: PropTypes.array,
    contentImage: PropTypes.string,
    contentImageWidth: PropTypes.string,
    contentImageHeight: PropTypes.string,
    contentImageClass: PropTypes.string,
    contentImageWebp: PropTypes.string,
    contentImageAlt: PropTypes.string,
    animation: PropTypes.bool,
    animationType: PropTypes.string,
    animationDuration: PropTypes.string,
    animationDelay: PropTypes.string,
    animationEasing: PropTypes.string,
    spacing: PropTypes.string,
    center: PropTypes.bool,
    containerClasses: PropTypes.string,
    rowClasses: PropTypes.string,
    refProp: PropTypes.object
}

Section.defaultProps = {
    animation: true,
    animationType: 'fade',
    animationDuration: '2000',
    animationDelay: '200',
    animationEasing: 'ease-out-quart',
    spacing: 'py-4 py-sm-5',
    contentImageWidth: '400',
    contentImageHeight: '400',
    fluid: false
}

export default Section